.content-header {
  margin-bottom: em(26);

  h2 {
    line-height: 1;
  }
}

// sub Heading
.sub-ht3 {
  font-size: em(16);
  font-weight: 600;
  color: $scorpion;
}

.filter-wrapper {
  margin-bottom: em(24);
}

.btb-clear-filter {
  color: $manatee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: em(5) 0;

  .btn-icon {
    font-size: em(18);
    margin-right: em(6);
  }

  &:hover {
    color: $primary;

    .btn-icon:before {
      color: $primary;
    }
  }
}

// Forms Styles
.form-horizontal-t2 {
  .ant-form-item-label label {
    padding-top: em(11);
    padding-right: em(6);

    &::after {
      content: "";
    }
  }
}

.form-layout-2 {
  .ant-row {
    flex-direction: row;

    .ant-form-item-label {
      text-align: right;
    }
  }

  .ant-col {
    width: 50%;
  }
}

.form-layout-3 {
  .ant-row {
    flex-direction: inherit;
  }
}

// invoice-total-wrapper
.invoice-total-wrapper {
  td.itw-row-1 {
    padding-top: em(40) !important;
    padding-bottom: 0 !important;
  }

  td.itw-row-2 {
    &.label-total-amount {
      font-size: rem(14);
      font-weight: 600;
      line-height: rem(34);
    }

    &.label-amount {
      .ant-typography {
        font-size: rem(20);
        color: $primary;
        font-weight: 600;
      }
    }

    .has-bg {
      border-radius: 5px;
      background-color: #f7f7f7;
      padding: em(10) em(12) em(6);
      color: #b3b4b7 !important;
      width: 100%;
      font-size: rem(18) !important;
      float: left;
      text-align: right;
    }
  }
}

// Forms Labels
.form-view-t2 {
  .ant-col.col-label {
    flex: 0 0 154px;
  }

  .ant-col.col-value {
    flex: 1 1 auto;
  }
}

.f-info-label {
  font-size: em(13);
}

.f-info-value {
  font-size: em(13);
  font-weight: 600;
}

.ant-pagination-options {
  position: absolute;
  right: 0;
}

// Add Manually Upload
.add-manually-wrapper {
  background-color: $primary;
  border-radius: em($base-border-radius);
  margin: em(24) 0 em(4);
  padding: 0 em(37);
  height: 73.2%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  background: url("../../../assets/images/bg-add-manually.jpg") no-repeat center center $primary;

  img {
    margin-bottom: rem(22);
    width: rem(80);
  }

  .label-add-head {
    font-size: rem(20);
    font-weight: 500;
    margin: 0 0 em(8) 0;
  }

  .label-add-des {
    font-size: rem(14);
    color: #a6e4e7;
    text-align: center;
    margin: 0 0 em(20) 0;
  }
}

// Application Buttons

.btn-tertiary {
  background: $white;
  border-color: $white;
  color: $primary;
  font-size: rem(13);
  border-radius: em($btn-border-radius);
  font-weight: 400;
  height: rem(32);
  min-width: rem(80);
  line-height: rem(25);

  &:hover,
  &:active,
  &:focus {
    background-color: #cce7e9;
    border-color: #cce7e9;
    color: #555757;

    .btn-icon {
      &::before {
        color: #555757;
      }
    }
  }

  .btn-icon {
    margin-right: rem(8);

    &::before {
      color: $primary;
    }
  }

  &.btn-clr-type3 {
    border-color: $secondary;
    color: $secondary;

    .btn-icon {
      &::before {
        color: $secondary;
      }
    }

    &:hover {
      border-color: $primary;
    }
  }

  &.btn-clr-type4 {
    border-color: #fbfbfb;
    color: #454949;
    font-weight: 600;
    background-color: #fbfbfb;

    .btn-icon {
      &::before {
        color: #454949;
        font-weight: 600;
      }
    }

    &:hover {
      border-color: #454949;
    }
  }

  &.btn-clr-type5 {
    border-color: #fbfbfb;
    color: $primary;
    font-weight: 600;
    background-color: #fbfbfb;

    .btn-icon {
      &::before {
        color: $primary;
        font-weight: 600;
      }
    }

    &:hover {
      border-color: $primary;
    }
  }
}

.btn-report-view {
  background: $white;
  border-color: $niagara;
  border-width: em(2);
  color: $primary;
  font-size: rem(13);
  border-radius: em($btn-border-radius);
  font-weight: 400;
  height: rem(32);
  min-width: rem(80);
  line-height: rem(25);

  &:hover,
  &:active,
  &:focus {
    background-color: $niagara;
    border-color: $niagara;
    color: $white;
  }
}

.report-counter {
  background-color: #dff7f7;
  min-height: rem(88);
  text-align: center;
  padding: rem(18) rem(18) rem(12) rem(18);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: rem($base-border-radius);

  .report-counter__count {
    font-size: rem(26);
    margin-bottom: em(4);
    color: #042828;
    font-weight: bold;
  }

  .report-counter__title {
    color: $scorpion;
    font-size: rem(13);
    font-weight: 600;
    margin: 0;
  }


}


.discrepancy-message-box {
  display: flex;
  background: $bridesmaid;
  border: solid 2px $coral;
  font-size: rem(12);
  padding: rem(13) rem(18) rem(11);
  color: $scorpion;
  line-height: 1;
  font-weight: 600;

  p {
    display: flex;
    align-items: center;
    border-right: solid 1px #b0b0b0;
    margin: 0 12px 0 0 !important;

    &:last-child {
      border-right: 0;
    }

    .icon-warning {
      line-height: 0 !important;
      font-size: rem(14) !important;
    }

    .label-msg {
      line-height: rem(16);
      padding: 2px 0 0 0;
      margin: 0 12px 0 8px;
    }
  }
}

.label-descrepancy-in-data {
  color: $bittersweet !important;
  font-weight: normal;
  float: left;
  font-size: rem(13);
}

// Dashboard

.dashboard-wrapper {
  display: flex;
  flex-direction: row;

  .col-left {
    background: #FAFAFA;
    padding: 24px 12px;
    flex: 1;
  }

  .col-right {
    min-height: 100vh;
    background: white;
    flex-wrap: wrap;
    word-break: break-all;
    border-left: 1px solid #E6F1E6;
    padding: 24px 20px;
    flex: 0 0 330px;
  }

}

.dashboard-card-t1 {
  .ant-card-body {
    padding: 30px;
  }

  height: 100%;

  h3 {
    font-size: em(20);
    font-weight: 600;
  }

  .ico-box {
    width: 84px;
    height: 85px;
    border-radius: 8px;

    .box-ico {
      &::before {
        color: $white;
        font-size: 55px;
      }
    }
  }

  .ico-box-rounded {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: red;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;


    .box-ico {

      &::before {
        font-size: 14px;
      }
    }
  }

}



.dashboard-number-box {
  .ant-card-body {
    padding: em(44) em(24) !important;

    .ico-box {
      color: $white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .dnb-details {
    width: 100;
    text-align: right;

    h1 {
      font-size: rem(46);
      font-weight: bold;
      color: $mine-shaft;
      margin: 0;
    }

    h4 {
      margin: 0;
      padding: 0;
      font-size: em(18);
      color: $manatee;
      font-weight: 600;
    }
  }

  .number-data {
    display: flex;
    flex-direction: column;

    h1 {
      font-size: rem(20);
      font-weight: 600;
      color: $mine-shaft;
      margin: 0;
      line-height: 1.5;
    }

    h4 {
      margin: 4px 0 0 0;
      padding: 0;
      font-size: em(14);
      color: #8F8F8F;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: .8px;
      width: 30%;
      overflow-wrap: initial;
    }
  }

  &.clr-t1 {
    .ico-box {
      background: $java;
    }
  }

  &.clr-t2 {
    .ico-box {
      background: $coral;
    }
  }

  &.clr-t3 {
    .ico-box {
      background: $primary;
    }
  }

  &.only-numbers {
    .ant-card-body {
      padding-top: em(30) !important;
      padding-bottom: em(30) !important;
    }

    .heading {
      color: $black;
      font-size: rem(18);
    }

    .dnb-details {
      text-align: left;

      h1 {
        font-size: em(36);
      }

      h4 {
        font-size: em(16);
      }
    }
  }

  &.combine-data {
    .ant-card-body {
      padding-top: em(28) !important;
      padding-bottom: em(28) !important;
    }
  }
}

.round-clr-t1 {
  .ico-box-rounded {
    background: #EBFFFF;

    .box-ico {
      &::before {
        color: #13C2C2;
      }
    }
  }
}


.round-clr-t2 {
  .ico-box-rounded {
    background: #FFF7EB;

    .box-ico {
      &::before {
        color: #FEA31B;
      }
    }
  }
}

.round-clr-t3 {
  .ico-box-rounded {
    background: #EDFCF7;

    .box-ico {
      &::before {
        color: #22D792;
      }
    }
  }
}

.round-clr-t4 {
  .ico-box-rounded {
    background: #FFEBEB;

    .box-ico {
      &::before {
        color: #FE5050;
      }
    }
  }
}

.round-clr-t5 {
  .ico-box-rounded {
    background: #F4EBFF;

    .box-ico {
      &::before {
        color: #9D50FE;
      }
    }
  }
}

.round-clr-t6 {
  .ico-box-rounded {
    background: #FFEBFA;

    .box-ico {
      &::before {
        color: #FE50D8;
      }
    }
  }
}

.dashboard-add-contract-box {
  background: url("../../../assets/images/bg-dashboard-contract-box.png") no-repeat right center $primary;
  background-size: cover;
  color: $white;

  h4 {
    color: $white;
    font-size: rem(18);
  }

  p {
    color: #a6e4e7;
    font-size: rem(13);
  }

  .db-box-icon {
    background: url("../../../assets/images/bg-ico-dashboard-contract.png") no-repeat right center transparent;
    width: 95px;
    height: 93px;
    background-size: contain;
  }
}

.dashboard-add-invoice-box {
  background: url("../../../assets/images/bg-dashboard-add-invoice.png") no-repeat right center $bittersweet;
  background-size: cover;
  color: $white;

  h4 {
    color: $white;
    font-size: rem(18);
  }

  p {
    color: #ffc5b4;
  }

  .db-box-icon {
    background: url("../../../assets/images/bg-ico-dashboard-add-invoice.png") no-repeat right center transparent;
    width: 95px;
    height: 93px;
    background-size: contain;
  }
}

.db-row {
  margin-bottom: em(24);

}

.dt-row {
  margin-top: em(24);
}

.dashboad-chart-box {}

.dashboad-table-box {
  .ant-table-thead {
    .ant-table-cell {
      border: 0;
    }
  }
}

.db-legend-t1 {
  .ant-badge {
    &:first-child {
      margin-left: 0;
    }

    margin-left: em(24);

    .ant-badge-status-dot {
      width: rem(10);
      height: rem(10);
    }

    .ant-badge-status-text {
      color: $manatee;
      font-size: rem(12);
      font-weight: 600;
      margin: rem(2) 0 0 rem(8);
      float: right;
    }
  }
}

.legend-wrapper {
  width: 100%;
  margin: em(26) 0 0 0;

  .inner-box {
    float: left;
    width: 65%;

    .ant-badge-status-dot {
      margin-top: em(5);
      float: left;
    }

    .ant-badge-status-text {
      float: left;
      width: 81%;
    }
  }

  .db-legend-t2:nth-child(2) {
    .ant-badge-vale-text {
      padding: 0;
    }
  }
}

.db-legend-t2 {
  @extend .db-legend-t1;

  float: left;
  margin: 0 0 em(15) 0;

  .ant-badge {
    margin-right: em(6);
    margin-left: 0;
    width: 100%;
  }

  .ant-badge-status-text {
    margin-top: 0 !important;
  }

  .ant-badge-status-dot {
    margin-top: em(5);
    float: left;
  }

  .ant-badge-status-text {
    float: left;
    width: 81%;
  }

  .ant-badge-vale-text {
    font-weight: bold;
    color: $mine-shaft;
    float: none !important;
    width: 25%;
    text-align: right;
  }
}

.db-tabs-buttons-wrapper-t2 {
  display: flex;
  float: right;
  font-size: em(12);
  color: #888997;
  overflow: hidden;
  min-width: rem(158);

  span {
    text-align: center;
    min-width: rem(80);
    line-height: rem(22);
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0.2px;
    height: rem(22);
    padding-left: rem(10);
    padding-right: rem(10);
  }

  .activateLink {
    border-radius: rem(12);
    background: #cceef0;
    color: $niagara;
  }
}

.db-tabs-buttons-wrapper {
  background: #f4f5f6;
  display: flex;
  float: right;
  border-radius: rem($base-border-radius);
  font-size: em(13);
  color: #5a5a5a;
  overflow: hidden;

  span {
    text-align: center;
    width: rem(136);
    line-height: 32px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0.3px;
  }

  .activateLink {
    background: $niagara;
    color: $white;
  }
}

.d-flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: auto !important;
  padding: 10px;
  border: 1px solid darkgrey;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 1px #ccc;
}

.react-pdf__Page {
  margin: 20px 0 20px 0;
}

/* country code field */
.react-tel-input {
  font-family: inherit !important;
  font-size: inherit !important;

  .form-control {
    height: rem(40) !important;
    border-color: #dadada !important;
  }
}

.pie-font {
  text-align: center;
  font-size: rem(20);

  .pie-label{
     font-weight: 100;
  color: #8f8f8f;
  }

  .pie-numbers{
    color: $secondary;
    padding-top: rem(6);
  }

}



.right-score-card-wrapper {
  h5 {
    margin: 4px 0 0 0;
    padding: 0;
    font-size: em(14);
    color: #8F8F8F;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: .8px;
  }

}

.vendor-score-meter-wrapper{
  background-color: transparent;
  perspective: 1000px;
  width: 100%;
  display: flex;
  height: 84px;
  cursor: pointer;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.vendorScore-meter-block, .vendorScore-breakdown-block {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
}

.vendorScore-meter-block {

}

.vendorScore-breakdown-block {
    background-color: #08979c;
  transform: rotateY(180deg);
  border-radius: 4px;
  color: #fff;
  padding: 4px 6px;
  text-align: left;
  font-size: rem(12);

  p{
    margin: 0 ;
  }
}
.vendor-score-meter-wrapper:hover .flip-card-inner {
  transform: rotateY(180deg);
}
