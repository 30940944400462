@import "../../../assets/scss/variables/_variable.scss";


.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.top-vertical-align {
  vertical-align: top;
}

.middle-vertical-align {
  vertical-align: middle;
}

.bottom-vertical-align {
  vertical-align: bottom;
}


// push
//
// push class element

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-right-im {
  float: right !important;
}

.no-border {
  border: 0;
}

// T2  =  Black heading color
.clr-heading-t2{
  color: $black;
}

.clr-link{
  color: $manatee;

  &:hover{
    color: $primary;
  }
}

.base-border-radius{
  border-radius: $base-border-radius;
}

.no-background{
  background: transparent !important; 
}


.sec-box-shadow-t1{
  box-shadow: 0 5px 6px 0 rgba(0,0,0,0.16) !important;
}

.sec-box-shadow-t1-inset{
  box-shadow: inset 0 5px 6px 0 rgba(0,0,0,0.16) !important;
}

.overflow-h{
  overflow: hidden;
}

.overflow-y{
     overflow-y: auto; 
     overflow-x: hidden;
}

.light {
  font-size: 2px;
}

.f2{
  font-size: em(20);
}

.f1-4{
  font-size: em(14);
}

.bold{
  font-weight: bold;
}

.bold-im{
  font-weight: bold !important;
}

// spacing


.m-0{
  margin: 0 !important;
}



.mb-1{
	margin-bottom: 6px;
}

.ml-1-im{
  margin-left: 6px !important;
}

.mt-0{
  margin-top: 0;
}

.mt-0-im{
  margin-top: 0 !important;
}

.mt-1{
  margin-top: rem(16);;
}

.mt-2{
  margin-top: rem(32);
}

.mt-2-im{
  margin-top: 1.5em !important;
}

.mb-0-5{
  margin-bottom: em(8);
}

.mb-1{
  margin-bottom: em(16);
}

.mb-1-5{
  margin-bottom: em(24);
}

.mb-1-im{
  margin-bottom: em(16) !important;
}

.mr-0{
  margin-right: 0;
}

.mr-0{
  margin-right: 0 !important;
}

.mr-1{
  margin-right: em(16) !important;
}

.mr-2{
  margin-right: em(32) !important;
}

.ml-1{
  margin-left: em(16) !important;
}

.ml-2{
  margin-left: em(32) !important;
}

.p-0{
  padding:  0 !important;
}

.pl-0{
  padding-left:  0 !important;
}
.pr-0{
  padding-left:  0 !important;
}

.pb-0-im{
  padding-bottom: 0 !important;
}

.pl-0-im{
  padding-left: 0 !important;
}

.pt-1{
  padding-top: em(16) !important;
}

.pt-2{
  padding-top: em(32) !important;
}

.pr-2{
  padding-right: em(32) !important;
}




// custom spaceing

.mb-t1{
  margin-bottom: em(20) !important;
}

.mb-t2{
  margin-bottom: em(26) !important;
}




// Flex Custom Classes

.d-flex{
  display: flex;
}

.f-wrap-wrap{
  flex-wrap: wrap;
}

.f-justify-end{
  justify-content: flex-end;
}

.f-justify-center{
  justify-content: center;
}

.f-align-item-center{
  align-items: center;
}





// Height
.p-height-100{
  height: 100%;
}


// Width 

.w-100{
  width: 100%;
}



// Display

.d-block{
  display: block;
}


// -- Typography

.lh-1{
  line-height: 1;
}




// Spaceing for section

.cus-section-mb-t1{
  margin-bottom: em(40);
}

// hr
.border-bottom-t1{
  border-bottom: $gallery solid 1px;
}
