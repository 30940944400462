/*!
 * Trupacta Application
 * Copyright 2019 Trupacta Application
 */


// /* You can add global styles to this file, and also import other style files */
@import "antd/dist/antd.css";

// Trupacta Application Variable
//
// Over ride Standerd Bootstrap Variable with Application Variables.
@import "variables/_variable.scss";

// Helpers
@import "helpers/_functions.scss";
@import "helpers/_utilities.scss";
@import "helpers/_helpers.scss";
@import "helpers/_mixins.scss";
@import "helpers/_application-icons.scss";

// Layout
@import "layout/_sidebar";
@import "layout/_header";
@import "layout/_content";

@import "components/_ant-override.scss";
@import "components/_common.scss";

body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-base;
  line-height: 20px;
  font-size: 16px;
  color: $body-color;
  background-color: $body-bg;
  // overflow-y: scroll;
  // min-width: 1024px;

  .ant-layout-sider{
    height: calc(100vh - 18px);
    min-height: -webkit-fill-available;
  }
}

// orignal Tag
a{
   &:active{
    text-decoration: none;
   }

   &:hover{
    text-decoration: none;
   }
}

h2{
  font-size : rem(22);
  line-height: em(20);
  // font-weight: 400;
  color: $clr-h2;  
}

h3{
  color: $clr-h3;
  font-size : rem(23);//em(24); // Addjust the font in size
  font-weight: 500;
  line-height: em(23);//em(24);  
}

h4{
  font-size : rem(20);
  line-height: em(20);
  font-weight: 600;
  color: $clr-h4;  
  margin-bottom: rem(22);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


// Layout
// @import "layout/_layout.scss";
// @import "layout/_tablet.scss";
@import "layout/_desktop";
@import "layout/_sidebar.scss";

@import "components/dark-theme";