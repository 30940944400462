.side-logo {
    padding: em(20) em(20) 0 em(20);

    img {
        width: 96%;
    }
}

.collapse-side-logo {
    padding: em(19) em(15) 0 em(12);
}

aside.ant-layout-sider.tp_sidebar_main {
    background-color: $white !important;
    overflow: hidden;
}

.primary-nav {
    margin-top: em(20);

    .ant-menu-item {
        align-items: center;
        color: $clr-primary-nav-link;
        display: flex;
        font-size: rem(15);
        font-weight: 600;
        margin-bottom: rem(20) !important;
        padding-left: rem(26);
        // height: 100%;

        &:hover {
            color: $clr-primary-nav-link-selected;
        }

        a {
            color: $clr-primary-nav-link;
            display: flex;
            align-items: center;
            height: 100%;
        }

        &.ant-menu-item-selected {

            a {
                color: $clr-primary-nav-link-selected;

                .menu-item-font-icon {
                    &::before {
                        color: $primary;
                    }
                }
            }
        }

        .menu-item-font-icon {
            float: left;
            font-size: em(23);

            &::before {
                color: $clr-primary-nav-link;
            }
        }

        .menu-item-label {
            float: left;
            line-height: em(24);
            padding: 0 0 0 rem(20);
        }
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right-color: $primary;
    border-right-width: rem(4);
}

.ant-layout-sider-collapsed {
    .menu-item-label {
        opacity: 0;
    }

    .ant-menu-item a::after {
        right: -9px;
    }

    .ant-menu-vertical {
        .ant-menu-item-selected {
            &::after {

                right: 9px;
            }
        }
    }
}

.btn-menu-toggle {
    cursor: pointer;
    font-size: rem(30);
    margin: rem(5) rem(26) 0 0;
    color: $black;
    background: no-repeat;
    border: none;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
        background: none;
        border: none;
        box-shadow: none;
        color: $primary;
    }
}